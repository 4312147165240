// fix weird off canvas design issues when the body is smaller then the viewport
.off-canvas-wrapper,
.off-canvas-wrapper-inner,
.off-canvas-content,
.off-canvas {
    @include breakpoint(medium down) {
        min-height: 100vh;
    }
}

// show our off canvas on large screens
.off-canvas {
    @include zindex(overlay, 1);

    padding-top: $spacing * 2;
    text-align: center;

    @include breakpoint(xmedium) {
        @include zindex(base);

        position: static;
        top: auto;
        right: auto;
        transform: none;
        transition: none;
        background-color: $body-background;
        padding: 0;
        width: 100%;
        height: auto;
        overflow: visible;
    }

    &.is-closed {
        @include breakpoint(xmedium) {
            visibility: visible;
        }
    }
}

// fix headroom to the top when the off canvas is open
.js-off-canvas-opened .headroom {
    @include breakpoint(medium down) {
        position: fixed;
        transform: translateY(0);
    }
}

// slide our "close off canvas on click" div too
.js-off-canvas-overlay {
    @include zindex(overlay, 1);

    &.is-visible {
        visibility: hidden;
        opacity: 0;
    }

    &.is-closable {
        @include breakpoint(medium down) {
            transform: translateX(map-get($offcanvas-sizes, small) * -1);
            visibility: visible;
            opacity: 1;
        }
    }
}

// only animate on  screen sizes where off canvas is active
.header,
.off-canvas-content,
.js-off-canvas-overlay {
    transition: none;

    @include breakpoint(mobile down) {
        transition: transform $offcanvas-transition-length $offcanvas-transition-timing;
    }
}
