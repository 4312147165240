img {
    .content & {
        border-radius: $global-radius;
    }

    .video-container & {
        border-radius: 0;
    }
}

.content .img--flat {
    border-radius: 0;
}
