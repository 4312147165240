ul {
    list-style: none;

    li {
        position: relative;
        margin-top: $spacing / 2;
        padding-bottom: $spacing / 3.5;
        padding-left: 1em;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            border-bottom: 0;
        }

        &::before {
            display: inline-block;
            position: absolute;
            top: 0.25rem + rem-calc(2);
            left: 0.25rem;
            border-radius: 50% 0;
            background-color: currentColor;
            width: rem-calc(8);
            height: rem-calc(8);
            content: '';
        }

        nav &::before {
            @extend %icon;

            top: 0;
            border-radius: 0;
            background: none;
            width: auto;
            height: auto;
            font-size: 0.75em;
            content: icon-char('arrow-right');
        }

        nav &.nav-list-item--back::before {
            content: icon-char('arrow-left');
        }

        .content &,
        .footer &,
        .cross-nav & {
            border-bottom: 2px solid color('pale-grey');

            &:last-child {
                border-bottom: 0;
            }
        }
    }
}

// no margin bottom of sub lists
ul,
ol {
    ul,
    ol {
        margin-top: 0;
        margin-bottom: 0;
    }
}

// The list-bare class simply removes any indents and bullet points from lists.
.list--bare,
%list--bare {
    margin: 0;
    padding: 0;

    > li {
        margin-top: 0;
        padding: 0;

        &::before {
            display: none;
        }
    }
}


// The list-inline class simply displays a list of items in one line.
.list--inline,
%list--inline {
    margin: 0;
    padding: 0;
    list-style: none;

    > li {
        display: inline-block;

        &::after {
            padding: $spacing / 4;
            content: '|';
        }

        &:last-child::after {
            display: none;
            content: '';
        }
    }
}

// hide after decorator which should usually add an visual seperator
.list--no-seperator,
%list--no-seperator {
    > li::after {
        display: none;
        content: '';
    }
}
