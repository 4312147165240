$header-transition: transform $offcanvas-transition-length $offcanvas-transition-timing;

.headroom {
    @include zindex(header);

    transition: $header-transition;
    width: 100%;
    will-change: transform;
    backface-visibility: hidden;

    &--headroom {
        position: absolute;
        top: 0;
    }

    &--top {
        transform: translate3d(0, 0, 0);
        transition: none;
    }

    &--not-top {
        position: fixed;
        transform: translate3d(0, -100%, 0);
        transition: none;
    }

    &--fixed {
        position: fixed;
        transform: translate3d(0, 0, 0);
        transition: $header-transition;
    }

    &--unfixed {
        transition: $header-transition;
    }

    .is-login-form-open & {
        position: fixed;
        top: 0;
        transform: translate3d(0, 0, 0);
    }
}
