// lodash templated used by SPiNNWERK gulp to generate our icon font output

@font-face {
    font-family: 'icon';
    font-display: block;
    src: url('/assets/icons/icon.eot');
    src: url('/assets/icons/icon.eot?#iefix') format('eot'),
         url('/assets/icons/icon.woff') format('woff'),
         url('/assets/icons/icon.ttf') format('truetype');
}

.icon,
%icon {
    font-family: "icon";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    text-decoration: none;
    text-transform: none;
    // speak: none; // only necessary if not using the private unicode range (firstGlyph option)
}

@function icon-char($filename) {
    $char: "";

    @if $filename == alert {
        $char: "\";
    }
    @if $filename == arrow-2ndLevel-left {
        $char: "\";
    }
    @if $filename == arrow-2ndLevel-right {
        $char: "\";
    }
    @if $filename == arrow-down {
        $char: "\";
    }
    @if $filename == arrow-left {
        $char: "\";
    }
    @if $filename == arrow-right {
        $char: "\";
    }
    @if $filename == arrow-up {
        $char: "\";
    }
    @if $filename == burger {
        $char: "\";
    }
    @if $filename == close {
        $char: "\";
    }
    @if $filename == download-DOC {
        $char: "\";
    }
    @if $filename == download-JPG {
        $char: "\";
    }
    @if $filename == download-PDF {
        $char: "\";
    }
    @if $filename == download-PNG {
        $char: "\";
    }
    @if $filename == download-PPT {
        $char: "\";
    }
    @if $filename == download-XLS {
        $char: "\";
    }
    @if $filename == download-generic {
        $char: "\";
    }
    @if $filename == external {
        $char: "\";
    }
    @if $filename == facebook-messenger {
        $char: "\";
    }
    @if $filename == facebook {
        $char: "\";
    }
    @if $filename == flickr {
        $char: "\";
    }
    @if $filename == foursquare {
        $char: "\";
    }
    @if $filename == googleplus {
        $char: "\";
    }
    @if $filename == home {
        $char: "\";
    }
    @if $filename == instagram {
        $char: "\";
    }
    @if $filename == language {
        $char: "\";
    }
    @if $filename == lock-closed {
        $char: "\";
    }
    @if $filename == lock-open {
        $char: "\";
    }
    @if $filename == mail {
        $char: "\";
    }
    @if $filename == overview {
        $char: "\";
    }
    @if $filename == pdf {
        $char: "\";
    }
    @if $filename == pinterest {
        $char: "\";
    }
    @if $filename == scroll-up {
        $char: "\";
    }
    @if $filename == search {
        $char: "\";
    }
    @if $filename == tag {
        $char: "\";
    }
    @if $filename == twitter {
        $char: "\";
    }
    @if $filename == whatsapp {
        $char: "\";
    }
    @if $filename == xing {
        $char: "\";
    }
    @if $filename == youtube {
        $char: "\";
    }

    @return $char;
}

@mixin icon($filename, $insert: before) {
    display: inline-block;
    line-height: 1;

    &::#{$insert} {
        @extend %icon;
        @include fluid(100%);

        content: icon-char($filename);
    }
}

%icon--alert,
.icon--alert {
    @include icon(alert);
}
%icon--arrow-2ndLevel-left,
.icon--arrow-2ndLevel-left {
    @include icon(arrow-2ndLevel-left);
}
%icon--arrow-2ndLevel-right,
.icon--arrow-2ndLevel-right {
    @include icon(arrow-2ndLevel-right);
}
%icon--arrow-down,
.icon--arrow-down {
    @include icon(arrow-down);
}
%icon--arrow-left,
.icon--arrow-left {
    @include icon(arrow-left);
}
%icon--arrow-right,
.icon--arrow-right {
    @include icon(arrow-right);
}
%icon--arrow-up,
.icon--arrow-up {
    @include icon(arrow-up);
}
%icon--burger,
.icon--burger {
    @include icon(burger);
}
%icon--close,
.icon--close {
    @include icon(close);
}
%icon--download-DOC,
.icon--download-DOC {
    @include icon(download-DOC);
}
%icon--download-JPG,
.icon--download-JPG {
    @include icon(download-JPG);
}
%icon--download-PDF,
.icon--download-PDF {
    @include icon(download-PDF);
}
%icon--download-PNG,
.icon--download-PNG {
    @include icon(download-PNG);
}
%icon--download-PPT,
.icon--download-PPT {
    @include icon(download-PPT);
}
%icon--download-XLS,
.icon--download-XLS {
    @include icon(download-XLS);
}
%icon--download-generic,
.icon--download-generic {
    @include icon(download-generic);
}
%icon--external,
.icon--external {
    @include icon(external);
}
%icon--facebook-messenger,
.icon--facebook-messenger {
    @include icon(facebook-messenger);
}
%icon--facebook,
.icon--facebook {
    @include icon(facebook);
}
%icon--flickr,
.icon--flickr {
    @include icon(flickr);
}
%icon--foursquare,
.icon--foursquare {
    @include icon(foursquare);
}
%icon--googleplus,
.icon--googleplus {
    @include icon(googleplus);
}
%icon--home,
.icon--home {
    @include icon(home);
}
%icon--instagram,
.icon--instagram {
    @include icon(instagram);
}
%icon--language,
.icon--language {
    @include icon(language);
}
%icon--lock-closed,
.icon--lock-closed {
    @include icon(lock-closed);
}
%icon--lock-open,
.icon--lock-open {
    @include icon(lock-open);
}
%icon--mail,
.icon--mail {
    @include icon(mail);
}
%icon--overview,
.icon--overview {
    @include icon(overview);
}
%icon--pdf,
.icon--pdf {
    @include icon(pdf);
}
%icon--pinterest,
.icon--pinterest {
    @include icon(pinterest);
}
%icon--scroll-up,
.icon--scroll-up {
    @include icon(scroll-up);
}
%icon--search,
.icon--search {
    @include icon(search);
}
%icon--tag,
.icon--tag {
    @include icon(tag);
}
%icon--twitter,
.icon--twitter {
    @include icon(twitter);
}
%icon--whatsapp,
.icon--whatsapp {
    @include icon(whatsapp);
}
%icon--xing,
.icon--xing {
    @include icon(xing);
}
%icon--youtube,
.icon--youtube {
    @include icon(youtube);
}
