a.media-object,
.media-object {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: ($spacing * 1.5) 0;

    @include breakpoint(medium) {
        flex-wrap: nowrap;
    }

    @each $color in ('red', 'yellow', 'green', 'blue') {
        &--#{$color} {
            h2,
            h3 {
                color: color('#{$color}');
            }

            .btn {
                background-color: color('#{$color}');

                @include hover {
                    background-color: color('#{$color}-darker');
                }
            }
        }
    }

    &--darkblue {
        h2,
        h3 {
            color: color('primary');
        }

        .btn {
            background-color: color('primary');

            @include hover {
                background-color: color('marine-blue');
            }
        }
    }

    &__media {
        position: relative;
        width: 100%;

        img {
            display: block;
        }

        &.compat-object-fit {
            min-height: 150px;
        }

        img,
        &.compat-object-fit {
            border-radius: $global-radius $global-radius 0 0;
        }

        @include breakpoint(medium) {
            width: grid-col(4);

            img {
                position: absolute;
            }

            img,
            &.compat-object-fit {
                border-radius: $global-radius 0 0 $global-radius;
            }
        }
    }

    &--quote &__media {
        border: 3px solid color('cloudy-blue-2');
        border-bottom: 0;
        border-radius: $global-radius $global-radius 0 0;
        padding: $spacing;

        picture,
        img {
            border-radius: $global-radius;
            overflow: hidden;
        }

        picture {
            display: block;
            position: relative;
            padding-bottom: 100%;
            width: 100%;
            height: 0;
            overflow: hidden;

            img {
                position: absolute;
                width: 100%;
                max-width: none;
                height: 100%;
                max-height: none;
                object-fit: cover;
            }
        }

        @include breakpoint(medium) {
            border: 3px solid color('cloudy-blue-2');
            border-right: 0;
            border-radius: $global-radius 0 0 $global-radius;
            padding: $spacing * 2;
            width: grid-col(3);

            img {
                position: static;
            }
        }
    }

    &__content {
        position: relative;
        border: 3px solid color('cloudy-blue-2');
        border-top: 0;
        border-radius: 0 0 $global-radius $global-radius;
        padding: $spacing;
        width: 100%;

        @include breakpoint(medium) {
            border-top: 3px solid color('cloudy-blue-2');
            border-left: 0;
            border-radius: 0 $global-radius $global-radius 0;
            width: grid-col(8);
        }

        *:first-child {
            margin-top: 0;
        }

        *:last-child {
            margin-bottom: 0;
        }

        .btn {
            position: absolute;
            right: -2px;
            bottom: -2px;
        }
    }

    &--simple &__content {
        border: 3px solid color('cloudy-blue-2');
        border-radius: $global-radius;
        width: 100%;
    }

    &--quote &__content {
        * {
            margin: 0;
        }

        @include breakpoint(medium) {
            width: grid-col(9);
        }
    }

    &--position {
        &-right {
            .media-object {
                &__media {
                    @include breakpoint(medium) {
                        img {
                            border-radius: 0 $global-radius $global-radius 0;
                        }
                    }
                }

                &__content {
                    @include breakpoint(medium) {
                        border-right: 0;
                        border-bottom: 3px solid color('cloudy-blue-2');
                        border-left: 3px solid color('cloudy-blue-2');
                        border-radius: $global-radius 0 0 $global-radius;
                    }

                    .btn--icon {
                        right: 0;
                        border-bottom-right-radius: 0;
                    }
                }
            }
        }
    }
}
