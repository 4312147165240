.login {
    &-form {
        transition: margin-top $animation-duration;
        margin-top: -$login-form-height;

        .is-login-form-open & {
            margin-top: 0;
        }

        form {
            height: $login-form-height;
        }

        &__visible-inputs {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding-top: 4px;
            height: 100%;
        }

        input {
            border-radius: $global-radius 0;
            padding: $button-padding;
            width: percentage(1 / 3);
            height: auto;
            font-size: 0.9rem;
        }

        input,
        &__actions {
            display: inline-block;
            margin-bottom: 0;
            margin-left: $spacing / 2;
            max-width: 200px;
        }

        .btn {
            display: inline-block;
            white-space: nowrap;
        }

        &__actions {
            position: relative;

            .link {
                position: absolute;
                top: calc(100% + 0.25em);
                left: 0;
            }
        }
    }

    &__errors {
        color: color('alert');
        font-size: 1.2rem;
        font-weight: $global-weight-bold;
    }
}
