// disable the extend linting because foundation doesn't support this
// sass-lint:disable placeholder-in-extend
fieldset {
    @extend .fieldset;

    legend {
        @extend h4;
        padding-top: $spacing;
        padding-bottom: $spacing / 2;
    }
}
// sass-lint:enable placeholder-in-extend
