// 1. Ensure the page always fills at least the entire height of the viewport.
// 2. apply a natural box layout model to all elements, but allowing components to change
//    http://www.paulirish.com/2012/box-sizing-border-box-ftw
// 3. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
//    navigating between pages that do/do not have enough content to produce
//    scrollbars naturally.
html {
    min-height: 100%;       // [1]
    box-sizing: border-box; // [2]
    overflow-y: scroll;     // [3]
}

input[type='search'], // override normalize search input style
*,
*::before,
*::after {
    box-sizing: inherit; // [1]
}

// 1. prevent possible cross browser z-index bugs
// 2. define a minimal with for very small devices
body {
    @include clearfix;
    @include zindex(base); // [1]
    min-width: $min-width; // [2]
}

// define color which our elements become when they are selected
::selection {
    background-color: color('selection');
}

// generate our available background classes
@include backgrounds-generate;
