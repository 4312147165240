.search {
    fieldset {
        margin: 0;
        border: 0;
        padding: 0;
    }
}

.search__input {
    margin: 0;
}

.search-results {
    h3 {
        display: inline-block;
        margin-top: 0;

        br {
            display: none;
        }
    }
}
