blockquote {
    display: inline-block;
    position: relative;
    padding: $spacing $spacing * 2;
    font-style: italic;

    > *:last-child {
        margin-bottom: 0;
    }

    &::before,
    &::after {
        position: absolute;
        line-height: $base-line-height;
        font-size: 2em;
        font-style: normal;
    }

    &::before {
        bottom: 0.2em;
        left: 0.5em;
        content: '\201D';
    }

    &::after {
        top: 0.6em;
        right: 0.5em;
        content: '\201C';
    }
}
