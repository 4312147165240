.hamburger {
    height: 14px;

    &.is-active {
        .hamburger-inner {
            transform: rotate(45deg);
            transition-delay: 0.14s;
            transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

            &::before {
                top: 0;
                transition: top 0.1s ease, opacity 0.1s ease 0.14s;
                opacity: 0;
            }

            &::after {
                bottom: 0;
                transform: rotate(-90deg);
                transition: bottom 0.1s ease, transform 0.1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.14s;
            }
        }
    }

    &-box {
        display: inline-block;
        position: relative;
        width: 14px;
        height: 14px;
    }

    &-inner {
        display: block;
        top: 50%;
        margin-top: -2px;
        transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
        transition-duration: 0.1s;

        &,
        &::after,
        &::before {
            position: absolute;
            transition: transform 0.15s ease;
            border-radius: 4px;
            background-color: $white;
            width: 14px;
            height: 3px;
        }

        &::after,
        &::before {
            display: block;
            content: '';
        }

        &::before {
            top: -5px;
            transition: top 0.1s ease 0.14s, opacity 0.1s ease;
        }

        &::after {
            bottom: -5px;
            transition: bottom 0.1s ease 0.14s, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }
    }
}
