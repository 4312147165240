// TODO: this template is a mess, it should not be re-used another time
.infotag {
    // Primary Nav
    .primary-nav {
        // default navigation design on big screens
        @include breakpoint(xmedium) {
            background-color: color('turquois-lighter');

            .is-dropdown-submenu {
                background-color: transparentize(color('turquois-lighter'), 0.2);

                .menu-item {
                    a {
                        border-bottom: 3px solid color('turquois');

                        @include hover {
                            background-color: color('turquois');
                        }
                    }
                }
            }

            .is-dropdown-submenu-item {
                &.current-menu-item {
                    a {
                        background-color: color('turquois');
                    }
                }
            }

            .accordion-menu {
                .menu-item {
                    a {
                        border-bottom: 3px solid color('turquois-darker');
                    }
                }

                .current-menu-item {
                    background-color: color('turquois-dark');
                }
            }

            > .menu > .menu-item {
                @include hover {
                    background-color: color('turquois');
                }
            }

            .current-page-ancestor,
            .current-menu-item {
                > a {
                    background-color: color('turquois');
                }
            }

            .is-dropdown-submenu {
                .current-menu-item,
                .current-menu-ancestor {
                    > a {
                        background-color: color('turquois-darkest');
                    }
                }
            }
        }
    }

    // off-canvas-btn
    .btn--menu-toggle {
        background-color: color('turquois-darker');
    }

    // Off-Canvas Element
    .off-canvas {
        // background
        background-color: color('turquois-lighter');

        .primary-nav {
            .accordion-menu {
                .menu-item a {
                    // underline
                    border-bottom-color: color('turquois');
                }

                // active LI
                .current-menu-item {
                    background-color: transparent;

                    > a {
                        background-color: color('turquois');
                    }

                    .submenu {
                        background-color: transparent;
                    }
                }
            }

            ul li a {
                color: color('white');
            }
        }

        // search
        .utility-nav .search input,
        .utility-nav .search .btn {
            background-color: color('turquois-darker');

            @include breakpoint(xmedium) {
                background-color: transparent;
            }
        }
    }

    // ECR Austria back button
    .btn--ecr-tag,
    .sidebar__block .btn {
        background-color: color('turquois-darker');

        &::after {
            background-color: color('turquois-darkest');
        }

        @include hover {
            &::after {
                background-color: color('turquois-darker');
            }
        }
    }

    // headlines & subline
    h2,
    .h2,
    h3,
    .h3,
    h4,
    .h4,
    h5,
    .h5,
    h6,
    .h6 {
        &,
        sub {
            // color: color('turquois-dark'); // old 2017
            color: color('turquois-lighter'); // new 2020
        }
    }

    .sidebar__block {
        h2,
        .h2,
        h3,
        .h3,
        a {
            color: color('white');
        }

        // blue bg too turquoise (e.g. aside)
        &.bg--blue {
            background-color: color('turquois');

            ul {
                li {
                    border-bottom-color: color('turquois-lighter');
                }
            }

            a:not(.accordion-title) {
                background-image: linear-gradient(0deg, color('turquois-lighter') calc(1em + 6px), transparent calc(1em + 6px));
            }
        }

        // new 2020 ==>
        &.bg--darkblue {
            background-color: color('turquois-darker');

            ul {
                li {
                    border-bottom-color: color('turquois-darkest');
                }
            }

            a:not(.accordion-title) {
                background-image: linear-gradient(0deg, color('turquois-darkest') calc(1em + 6px), transparent calc(1em + 6px));
            }
        }

        &.bg--green {
            background-color: color('turquois-darkest');
            color: color('primary');

            h2,
            h3,
            h4,
            h5,
            h6,
            a {
                color: inherit;
            }

            ul {
                li {
                    border-bottom-color: color('turquois-darker');
                }
            }

            a:not(.accordion-title) {
                background-image: linear-gradient(0deg, color('turquois-darker') calc(1em + 6px), transparent calc(1em + 6px));
            }
        }
    }
    // <==

    // table
    thead {
        background: color('turquois');

        td {
            border-left: 3px solid color('turquois-dark');
        }
    }

    // bg header img
    .bg--header-image {
        background-size: cover;
        height: 150px;
    }

    .block__list__item--turquois .btn {
        background-color: color('turquois-lighter');

        @include hover {
            background-color: color('turquois');
        }
    }

    .btn--submit {
        background-color: color('turquois-lighter');

        &::after {
            background-color: color('turquois');
        }

        @include hover {
            &::after {
                background-color: color('turquois-lighter');
            }
        }
    }
}
