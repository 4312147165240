input,
textarea,
select {
    .input-group & {
        margin-bottom: 0;
    }

    .has-error & {
        border-color: $alert-color;
    }

    .is-valid & {
        border-color: $success-color;
    }
}

textarea {
    resize: vertical;
}

select {
    display: block;
    width: 100%;
}
