table {
    overflow: hidden;
    border-collapse: separate;
    border-spacing: 0;

    thead,
    tfoot {
        td,
        th {
            border-left: 3px solid color('marine-blue');
            padding: calc(0.5em + 3px) $spacing / 2;

            &:first-child {
                border-left-color: transparent;
            }

            &:last-child {
                border-right: 3px solid transparent;
            }
        }
    }

    tbody {
        tr {
            td {
                border-top: 3px solid color('silver');
                border-left: 3px solid color('silver');

                &:last-child {
                    border-right: 3px solid color('silver');
                }
            }

            &:first-child {
                td {
                    &:first-child {
                        border-top-left-radius: $global-radius;
                    }

                    &:last-child {
                        border-top-right-radius: $global-radius;
                    }
                }
            }

            &:last-child {
                td {
                    border-bottom: 3px solid color('silver');

                    &:first-child {
                        border-bottom-left-radius: $global-radius;
                    }

                    &:last-child {
                        border-bottom-right-radius: $global-radius;
                    }
                }
            }
        }
    }

    thead ~ tbody {
        tr:first-child {
            td {
                border-top: 0;

                &:first-child {
                    border-top-left-radius: 0;
                }

                &:last-child {
                    border-top-right-radius: 0;
                }
            }
        }
    }

    tfoot ~ tbody {
        &:last-child {
            td {
                border-bottom: 0;

                &:first-child {
                    border-bottom-left-radius: 0;
                }

                &:last-child {
                    border-bottom-right-radius: 0;
                }
            }
        }
    }
}

.table-scroll {
    margin-bottom: $spacing;
    overflow-x: hidden;

    &-indicators {
        position: relative;

        &__indicator {
            display: none;
            position: absolute;
            top: 0;
            width: $spacing * 4;
            height: 100%;

            .btn {
                position: absolute;
                bottom: 0;
                background-color: color('silver');

                .icon {
                    pointer-events: none;
                }

                @include hover {
                    background-color: color('bluey-grey');
                }

            }

            &--left {
                left: 0;
                border-left: 3px dashed color('silver');

                .btn {
                    left: -3px;
                    border-radius: 0 $global-radius 0 0;
                    cursor: w-resize;
                }
            }

            &--right {
                right: 0;
                border-right: 3px dashed color('silver');

                .btn {
                    right: -3px;
                    border-bottom-right-radius: 0;
                    cursor: e-resize;
                }
            }

            &.is-visible {
                display: block;
            }
        }
    }

    table {
        margin: 0;
        min-width: 100%;
    }
}
