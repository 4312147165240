.object-fit {
    > img {
        @include object-fit;
    }
}

// object fit polyfill for IE
.compat-object-fit {
    display: block;
    background-position: center center;
    background-size: cover;

    img {
        @extend %visuallyhidden;
    }
}
