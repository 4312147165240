.breadcrumbs-container {
    .main-breadcrumbs {
        text-transform: uppercase;
        color: color('bluey-grey');
        font-size: 0.8em;

        a {
            color: color('bluey-grey');
        }
    }
}

.home {
    .breadcrumbs-container {
        display: none;
    }
}

body:not(.single-post) {
    .show-on-post {
        display: none;
    }
}
