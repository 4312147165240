@mixin no-link-icon {
    &::before {
        display: none;
    }
}

.main-breadcrumbs a,
.link,
p > a:not(.btn),
.sidebar a:not(.btn):not(.accordion-title) {
    transition: background-position $animation-duration;
    background-image: linear-gradient(0deg, color('cloudy-blue-2') calc(1em + 6px), transparent calc(1em + 6px));
    background-size: 100% 200%;
    background-position-y: 0;

    @include hover {
        background-position-y: -1em;
    }
}

// style links based on their usage
a[target='_blank']:not(.download) {
    @include icon('external');
}

a[href $='.pdf']:not(.download) {
    @include icon('pdf');
}

a[href ^='mailto:']:not(.download) {
    @include icon('mail');
}

// provide special class to prevent this
a[target='_blank']:not(.download),
a[href ^='mailto:']:not(.download),
a[href $='.pdf']:not(.download) {
    &::before {
        display: inline-block;
        padding-right: 0.25em;
        padding-bottom: 0;
        width: auto;
        height: auto;
        overflow: visible;
        font-size: 0.8em;
    }

    &.no-link-icon {
        @include no-link-icon;
    }
}
