// Colors
// -------
$colors: (
    'black': #0a0a0a,
    'white': #fff,
    'primary': #0a4270,
    'secondary': #767676,
    'success': #03875d,
    'alert': #e82a00,
    'light-gray': #e6e6e6,
    'medium-gray': #cacaca,
    'dark-gray': #8a8a8a,
    'selection': #accef7,
    'darkblue': #00213c,
    'marine-blue': #002f55,
    'peacock-blue': #005d9a,
    'sandy-brown': #ba9f61,
    'sandstone': #c6ad75,
    'very-light-brown': #d6bf8e,
    'cloudy-blue': #bfcad4,
    'cloudy-blue-2': #d5dde3,
    'silver': #b9c1c8,
    'pale-grey': #f1f4f6,
    'bluey-grey': #8ba0b1,
    'battleship-grey': #5f707e,
    'bright-magenta': #fd00ff,
    'yellow-lighter': #ffc13b,
    'yellow': #ffae00,
    'yellow-darker': #eb9303,
    'yellow-dark': #db7b07,
    'red-lighter': #d63a16,
    'red': #b62403,
    'red-darker': #921d03,
    'green-lighter': #05a270,
    'green': #03875d,
    'green-darker': #056d4c,
    'blue-lighter': #248ed3,
    'blue': #187fc2,
    'blue-darker': #006eb6,

    // legacy:
    // old template of 2017 got re-used 2022; these are the old colors:
    // 'turquois-lighter': #26d0d5,
    // 'turquois': #1bb6bb,
    // 'turquois-dark': #019b94,
    // 'turquois-darker': #00534f,
    // 'turquois-darkest': #0a4645,

    'turquois-lighter': #18a991,
    'turquois': #8cd4c8,
    'turquois-dark': #d1eee9,
    'turquois-darker': #fec911,
    'turquois-darkest': #ffe488,

    'forgetmenot-lighter': #abcfea,
    'forgetmenot': #94bfde,
    'forgetmenot-dark': #5b96c1,
    'forgetmenot-darker': #113854,
    'forgetmenot-darkest': #092a41,

    'infotag2019-lighter': #c9eacc,
    'infotag2019': #afe2b4,
    'infotag2019-dark': #9ddaa9,
    'infotag2019-darker': #113854,
    'infotag2019-darkest': #092a41,

    'infotag2020-lighter': #cd2f5d,
    'infotag2020': #9d2246,
    'infotag2020-dark': #9d2246,
    'infotag2020-darker': #113854,
    'infotag2020-darkest': #00324c,

    'infotag2021-lighter': #d273a8,
    'infotag2021': #be4988,
    'infotag2021-dark': #a23e76,
    'infotag2021-darker': #164069,
    'infotag2021-darkest': #00324c,
);

// function to easily access colors and also validate the input
@function color($col) {
    @if map-has-key($colors, $col) {
        @return map-get($colors, $col);
    } @else {
        @error 'The given color "#{$col}" does not exist';
    }
}
