.swiper {
    position: relative;

    &-wrapper {
        box-sizing: border-box;
    }

    &-container {
        width: 100%;
    }

    &-button-prev,
    &-button-next {
        position: absolute;
        background-image: none;
        width: 1.3em;

        &::after {
            @extend %icon;
            display: block;
            position: absolute;
            transition: background-color $animation-duration;
            border: 1px solid transparent;
            border-radius: 1rem 0;
            background-color: color('primary');
            padding: $button-padding;
            line-height: $line-height-base;
            color: color('white');
        }
    }

    &-button-prev {
        &::after {
            right: 50%;
            padding: 0.425em 0.5em 0.425em 0.4em;
            content: icon-char('arrow-left');
        }

        @include breakpoint(large down) {
            left: 0;

            &::after {
                right: initial;
                left: 0;
            }
        }
    }

    &-button-next {
        &::after {
            left: 50%;
            padding: 0.425em 0.4em 0.425em 0.5em;
            content: icon-char('arrow-right');
        }

        @include breakpoint(large down) {
            right: 0;

            &::after {
                right: 0;
                left: initial;
            }
        }
    }
}
