$_teaser-height: 50vw;
$_teaser-max-height: 280px;
$_teaser-tabs-max-height: $_teaser-max-height * 0.4;

.teaser {
    position: relative;
    transform: translateZ(0); // create correct stacking context for border-radius
    margin-bottom: $spacing;
    height: $_teaser-height;
    max-height: $_teaser-max-height;
    overflow: hidden;

    @include breakpoint(xmedium) {
        border-radius: 0 0 $global-radius $global-radius;
    }

    &--responsive:is(&--has-variants) {
        height: auto;
        max-height: none;

        @include breakpoint(xmedium) {
            height: $_teaser-height;
            max-height: $_teaser-max-height;
        }
    }

    &--responsive:is(&--orientation) {
        @include breakpoint(landscape) {
            height: $_teaser-height;
            max-height: $_teaser-max-height;
        }
    }

    &--hover {
        position: absolute;
        top: 0;
        bottom: 0;
        transition: opacity $animation-duration;
        opacity: 0;
        pointer-events: none;

        @each $bp, $gutter in $grid-column-gutter {
            @include breakpoint($bp) {
                right: rem-calc($gutter / 2);
                left: rem-calc($gutter / 2);
            }
        }
    }

    &-container {
        position: relative;

        @include breakpoint(medium down) {
            padding-right: 0;
            padding-left: 0;
        }

        @include hover {
            .teaser--hover {
                opacity: 1;
            }
        }
    }

    &__images {
        height: 100%;
    }

    &--static,
    &__images__image {
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__tabs {
        @include zindex(base, 2);

        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 20vw;
        max-height: $_teaser-tabs-max-height;

        @include breakpoint(rem-calc(560)) {
            height: 40%;
        }

        &__item {
            display: block;
            position: relative;
            float: left;
            transform: translate3d(0, percentage(120px / $_teaser-tabs-max-height), 0);
            transition: transform $animation-duration;
            margin-left: 2%;
            border-radius: $global-radius $global-radius 0 0;
            background-color: transparentize(color('yellow'), 0.2);
            width: 32%;
            height: 100%;

            @include breakpoint(mobile down) {
                position: absolute;
                left: 2%;
                transform: translate3d(0, 100%, 0);
                margin: 0;
                width: 96%;

                p {
                    margin-bottom: 0.5rem;
                }
            }

            a {
                display: block;
                padding: 1rem;
                height: 100%;
                color: color('white');
                font-weight: bold;
            }

            &:first-child {
                background-color: transparentize(color('blue-darker'), 0.2);

                @include breakpoint(medium) {
                    margin-left: 0;
                    border-top-left-radius: 0;
                }

                .btn {
                    background-color: color('blue-darker');
                }
            }

            &:last-child {
                background-color: transparentize(color('red'), 0.2);

                @include breakpoint(medium) {
                    &,
                    .btn {
                        border-top-right-radius: 0;
                    }
                }

                .btn {
                    background-color: color('red');
                }
            }

            .ecr-tag &,
            .infotag & {
                background-color: transparentize(color('white'), 0.2);

                a,
                .btn {
                    color: color('primary');
                }

                .btn {
                    background-color: color('white');
                }
            }

            @include hover {
                transform: translate3d(0, percentage(110px / $_teaser-tabs-max-height), 0);
            }

            &.is-active {
                transform: translate3d(0, 0, 0);

                .btn {
                    transform: translate3d(0, 0, 0);
                }
            }

            .btn {
                position: absolute;
                top: 0;
                right: 0;
                transform: translate3d(0, 100%, 0);
                transition: transform $animation-duration;
                border-radius: 0 $global-radius;
                background-color: color('yellow');
                pointer-events: none;
            }
        }
    }
}
