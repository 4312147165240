.newsletter {
    border-radius: $global-radius;
    padding: map-get($grid-column-gutter, small) / 2 0;

    @include breakpoint(medium) {
        padding: map-get($grid-column-gutter, medium) / 2 0;
    }

    h3 {
        margin-top: 0;
        font-size: 1rem;
    }

    h3,
    .sidebar__block.bg--none & h3 {
        color: color('white');
    }

    .column {
        display: inline-flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    .column:first-child {
        align-items: flex-start;
    }

    .column:last-child {
        align-items: flex-end;
    }

    .sidebar__block & .column {
        float: none;
        width: 100%;

        &:last-child {
            align-items: center;
        }
    }

    .label-floating {
        margin-right: 0;
    }

    .btn {
        flex: 0 0 auto;
    }
}
