.primary-nav {
    opacity: 0; // prevent flickering on loading
    text-align: left;

    .btn--close {
        position: absolute;
        top: 0;
        right: 0;
    }

    a {
        @include font-special;

        text-transform: uppercase;
        color: color('white');
        font-weight: 500;
    }

    .menu a {
        white-space: normal;
    }

    // basic nav styling
    ul {
        @extend %list--bare;
    }

    .menu-item {
        display: block;
    }

    .is-accordion-submenu-parent[aria-expanded='true'] > a {
        background-color: color('blue');
    }

    .is-dropdown-submenu {
        .menu-item {
            padding: 0 rem-calc(16);

            a {
                transition: background-color $animation-duration, padding-left $animation-duration, padding-right $animation-duration;
                border-bottom: 3px solid color('battleship-grey');
                padding: calc(0.7em + 3px) 1em 0.7em 0;
                font-size: rem-calc(16);

                @include hover {
                    background-color: color('darkblue');
                    padding-right: 0;
                    padding-left: 1em;

                }
            }

            &.is-dropdown-submenu-parent > a {
                display: block;
                position: relative;

                &::after {
                    @extend %icon;

                    position: absolute;
                    top: calc(1em + 3px);
                    right: 0;
                    transform: translate3d(50%, 0, 0);
                    transition: transform $animation-duration;
                    padding: 0;
                    width: auto;
                    height: auto;
                    content: icon-char('arrow-right');
                }

                @include hover {
                    &::after {
                        transform: translate3d(90%, 0, 0);
                    }
                }
            }

            &:last-child a {
                margin-bottom: 0.7em;
            }
        }
    }

    .accordion-menu {
        .menu-item {
            a {
                transition: background-color $animation-duration;
                margin-right: 0;
                border-bottom: 3px solid color('battleship-grey');
                padding: 0.7rem $accordionmenu-submenu-toggle-width 0.7rem 1rem;
            }

            .menu-item {
                padding-left: $spacing;
            }
        }

        .current-menu-item {
            background-color: color('blue');

            .submenu {
                background: $offcanvas-background;
            }
        }
    }

    // default navigation design on big screens
    @include breakpoint(xmedium) {
        position: absolute;
        right: 0;
        bottom: 0;
        left: calc(121px + #{$spacing * 2});
        background: background('primary');

        &,
        > .menu > .menu-item:first-child,
        > .menu > .menu-item:first-child > a {
            border-top-left-radius: $global-radius;
        }

        > .menu > .menu-item {
            transition: background $animation-duration;

            @include hover {
                background: background('peacock-blue');
            }
        }

        .menu-item {
            display: table-cell;
        }

        .current-page-ancestor,
        .current-menu-item {
            > a {
                background-color: color('peacock-blue');
            }
        }

        .is-dropdown-submenu {
            border-radius: 0 0 $global-radius $global-radius;
            padding-bottom: rem-calc(8);

            .current-menu-item,
            .current-menu-ancestor {
                > a {
                    background-color: color('peacock-blue');
                    padding-right: 0;
                    padding-left: 1rem;
                }
            }

            .is-dropdown-submenu {
                border-radius: 0 $global-radius $global-radius;
                padding: rem-calc(16) 0 rem-calc(8);
            }
        }
    }
}

.is-accordion-submenu-parent > .submenu-toggle {
    border-radius: 0;

    @include hover {
        outline: 0;
    }

    &::after {
        transition: transform $animation-duration;

    }
}
