.sidebar {
    margin-top: $spacing;

    @include breakpoint(medium) {
        margin-top: 0;
    }

    &__block {
        margin-bottom: $spacing;
        border-radius: $global-radius;
        padding: $spacing;

        &-wrapper {
            @include grid-col(12);

            @include breakpoint(mobile) {
                @include grid-col(6);
            }

            @include breakpoint(medium) {
                @include grid-col(12);

                padding-right: 0 !important; // sass-lint:disable-line no-important override column settings
            }
        }

        *:first-child {
            margin-top: 0;
        }

        &,
        h2,
        .h2,
        h3,
        .h3,
        a,
        .accordion-content {
            color: color('white');
        }

        h3,
        .h3 {
            margin-bottom: 1em;
            font-size: 1rem;
        }

        ul {
            margin: 0;

            li:not(.accordion-item) {
                border-bottom: 3px solid;

                &:last-child {
                    border-bottom: 0;
                }
            }
        }

        .accordion {
            &-title {
                padding-top: 0;
                padding-bottom: 0;

                &::before {
                    border-top-color: color('white');
                }
            }

            &-content {
                padding-right: 0;
                padding-bottom: 0;
                padding-left: 0;
            }

            &-content,
            & :last-child > .accordion-content:last-child {
                border-top-color: color('white');
            }
        }

        @each $color in ('yellow', 'red', 'green', 'blue') {
            @include on-background('#{$color}') {
                ul li:not(.accordion-item) {
                    border-bottom-color: color('#{$color}-lighter');
                }

                a:not(.accordion-title) {
                    background-image: linear-gradient(0deg, color('#{$color}-lighter') calc(1em + 6px), transparent calc(1em + 6px));
                }
            }
        }

        @include on-background('darkblue') {
            ul li:not(.accordion-item) {
                border-bottom-color: color('peacock-blue');
            }

            a:not(.accordion-title) {
                background-image: linear-gradient(0deg, color('peacock-blue') calc(1em + 6px), transparent calc(1em + 6px));
            }
        }

        @include on-background('none') {
            &,
            .h2,
            h3,
            .h3,
            .accordion-content {
                color: $body-font-color;
            }

            ul li:not(.accordion-item) {
                border-bottom-color: color('blue-lighter');
            }

            a:not(.btn) {
                color: $anchor-color;
            }

            .accordion {
                &-title::before {
                    border-top-color: $body-font-color;
                }

                &-content,
                & :last-child > .accordion-content:last-child {
                    border-top-color: color('cloudy-blue-2');
                }
            }
        }
    }

    &--without-padding {
        padding: 0;
    }

    .post__meta {
        :first-child {
            margin-top: 0;
        }
    }
}
