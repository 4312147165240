html {
    // scale up our font size when the viewport gets larger
    font-size: calc(#{$font-size-min} + (#{strip-units($font-size-max)} - #{strip-units($font-size-min)}) * ((100vw - #{$min-width}) / (#{strip-units($max-width)} - #{strip-units($min-width)})));

    // when we reached our max breakpoint stop scaling up our font size
    @media (min-width: $max-width) {
        font-size: $font-size-max;
    }
}

body {
    @include font-default;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    @include font-headline;

    margin-top: $spacing * 1.3;
    text-transform: uppercase;
    font-weight: 700;

    &,
    & sub {
        color: color('primary');
    }
}

h1,
.h1,
h3,
.h3 {
    color: color('bluey-grey');
}

h1 + h2,
.h1 + .h2,
h1 + .h2,
.h1 + h2,
h2 + h3,
.h2 + .h3,
h2 + .h3,
.h2 + h3,
h3 + h4,
.h3 + .h4,
h3 + .h4,
.h3 + h4 {
    margin-top: 0;
}

p {
    &:empty {
        display: none;
    }
}

[type='text'],
[type='password'],
[type='date'],
[type='datetime'],
[type='datetime-local'],
[type='month'],
[type='week'],
[type='email'],
[type='number'],
[type='search'],
[type='tel'],
[type='time'],
[type='url'],
[type='color'],
textarea {
    @include font-default;
}

.content *:first-child {
    margin-top: 0;
}
