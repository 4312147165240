.footer {
    margin-top: $spacing;
    padding-top: $spacing * 2;
    padding-bottom: $spacing * 6;

    .row {
        @include breakpoint(medium) {
            display: flex;
            flex-direction: row;
        }
    }

    .column {
        display: inline-flex;
        flex-direction: column;
        flex-wrap: wrap;
    }

    .column.row {
        flex-direction: row;
    }

    .copyright {
        margin-top: $spacing * 2;
        text-align: right;
    }

    &,
    a {
        color: color('battleship-grey');
    }

    a {
        @include hover {
            color: color('primary');
        }
    }

    .logo {
        margin-top: $spacing * 2;

        @include breakpoint(medium) {
            margin-top: 0;
        }

        svg {
            max-width: 144px;
            max-height: 94px;
        }
    }

    &__nav {
        ul {
            margin-left: 0;

            li {
                &::before {
                    top: 2px;
                    left: 0;
                }

                @include breakpoint(mobile down) {
                    border: 0;
                }
            }
        }
    }

    &__spinnwerk-claim {
        margin-top: $spacing * 2;

        @include breakpoint(medium) {
            margin-top: 0;
        }
    }
}
