.download {
    display: block;
    position: relative;
    margin: ($spacing * 1.5) 0;
    border: 3px solid color('cloudy-blue-2');
    border-radius: $global-radius;
    padding: $spacing $spacing $spacing $spacing * 4;
    min-height: 104px;

    &::before {
        @extend %icon;

        position: absolute;
        left: $spacing / 2;
        color: color('primary');
        font-size: 2.5rem;
        content: icon-char('download-generic');
    }

    &::after {
        @extend %icon;

        display: block;
        position: absolute;
        right: -3px;
        bottom: -3px;
        transition: background-color $animation-duration;
        border: 1px solid transparent;
        border-radius: $global-radius 0;
        background-color: color('primary');
        padding: $button-padding;
        line-height: $line-height-base;
        color: color('white');
        content: icon-char('arrow-right');
    }

    @include hover {
        &::after {
            background-color: color('marine-blue');
        }
    }

    + .download {
        margin-top: $spacing;
    }

    &--pdf::before {
        color: color('red');
        content: icon-char('download-PDF');
    }

    &--xlsx::before,
    &--xls::before {
        color: color('green');
        content: icon-char('download-XLS');
    }

    &--docx::before,
    &--doc::before {
        content: icon-char('download-DOC');
    }

    &--jpeg::before,
    &--jpg::before {
        content: icon-char('download-JPG');
    }

    &--png::before {
        content: icon-char('download-PNG');
    }

    &--ppt::before {
        content: icon-char('download-PPT');
    }

    &--restricted-access {
        &::before {
            content: icon-char('lock-closed');

        }

        &::after {
            display: none;
            content: '';
        }
    }

    &--with-preview {
        display: flex;
        border: 0;
        padding: 0;

        &::before {
            display: none;
        }

        &::after {
            right: 0;
            bottom: 0;
        }
    }

    &--short {
        display: inline;
        margin-top: 0;
        border: 0;
        border-radius: 0;
        padding: 0;
        min-height: 0;

        &::before {
            display: inline-block;
            position: static;
            color: inherit;
            font-size: 1rem;
        }

        &::after {
            display: none;
        }
    }

    &__preview {
        position: relative;

        &.compat-object-fit {
            min-height: 150px;
        }

        img,
        &.compat-object-fit {
            border-radius: $global-radius 0 0 $global-radius;
        }

        &.object-fit > img {
            position: absolute;
            overflow: hidden;
        }
    }

    &__content {
        border: 3px solid color('cloudy-blue-2');
        border-left: 0;
        border-radius: 0 $global-radius $global-radius 0;
        padding: $spacing;
    }

    &__title {
        margin-bottom: 10px;
        border-bottom: 3px solid color('pale-grey');
    }

    &__preview,
    &__content,
    &__title,
    &__meta {
        display: block;
    }

    &--short &__title {
        display: inline;
    }

    &__description {
        > *:last-child {
            margin-bottom: 0;
        }
    }

    &__meta {
        text-transform: uppercase;
        color: color('bluey-grey');
    }

    &--short &__meta {
        display: block;
        font-size: 0.8rem;
    }
}
