.callout {
    position: relative;
    margin: $callout-margin;
    border: $callout-border;
    border-radius: $callout-radius;
    background: $callout-background;
    padding: $callout-padding;
    text-align: left;
    color: $callout-font-color;

    @each $name, $var in $foundation-palette {
        &--#{$name} {
            border-color: $var;
        }
    }
}
