.accordion {
    &-item {
        padding: 0;

        &,
        .content &, {
            border: 0;
        }

        &::before {
            display: none;
        }
    }

    &-title {
        $_arrow-height: 6px;

        border: 0;
        padding-right: calc(#{$_arrow-height * 2} + #{$spacing});

        :last-child:not(.is-active) > & {
            border: 0;
            border-radius: 0;
        }

        &::before {
            right: 0;
            transform: translateY(-50%);
            transition: transform $animation-duration;
            margin-top: 0;
            border: inset $_arrow-height;
            border-bottom-width: 0;
            border-top-style: solid;
            border-color: color('primary') transparent transparent;
            content: '';
            transform-origin: center;
            will-change: transform;

            .is-active > & {
                transform: translateY(-50%) rotateX(180deg);
                content: '';
            }
        }

        .h2,
        .h3 {
            display: block;
            margin: 0;
        }
    }

    &-content {
        &,
        :last-child > &:last-child {
            border: 0;
            border-top: $accordion-content-border;
        }

        p:last-child {
            margin-bottom: 0;
        }
    }

    &-item:last-child > :last-child {
        border-radius: 0;
    }
}
