// overrides need !important
// sass-lint:disable no-important
.mejs-overlay-button {
    @include absolute-center;

    margin: 0 !important;
}

.mejs-button > button {
    @include vertical-center;

    border-radius: 0;
}

.mejs-controls .mejs-time-rail .mejs-time-total {
    @include vertical-center;
}
