// Backgrounds
// ------------
$backgrounds: (
    'white':   color('white'),
    'black':   color('black'),
    'cloudy-blue': color('cloudy-blue'),
    'cloudy-blue-2': color('cloudy-blue-2'),
    'peacock-blue': color('peacock-blue'),
    'pattern': 'url(/assets/img/dummy/background.png)',
    'yellow': color('yellow'),
    'red': color('red'),
    'green': color('green'),
    'blue': color('blue'),
    'primary': color('primary'),
    'none': none,
    'darkblue': color('darkblue'),
    'blue-lighter': color('blue-lighter'),
    'ecr-tag': var(--color--ecr-tag),
    'ecr-tag--lighter': var(--color--ecr-tag--lighter),
    'ecr-tag--dark': var(--color--ecr-tag--dark),
    'ecr-tag--darker': var(--color--ecr-tag--darker),
    'ecr-tag--darkest': var(--color--ecr-tag--darkest),
);

// funtion to easily access background values and also validate the input
@function background($bg) {
    @if map-has-key($backgrounds, $bg) {
        @return map-get($backgrounds, $bg);
    } @else {
        @error 'The given background "#{$bg}" does not exist';
    }
}

// mixin to generate all backgrounds based on the background map
@mixin backgrounds-generate {
    @each $name, $value in $backgrounds {
        .bg--#{$name} {
            background: #{$value};
        }
    }
}

// mixin to determine if the current element is inside, or itself,
// styled by a generated background class
@mixin on-background($bg) {
    @if map-has-key($backgrounds, $bg) {
        &.bg--#{$bg},
        .bg--#{$bg} & {
            @content;
        }
    } @else {
        @error 'The given background "#{$bg}" does not exist';
    }
}
