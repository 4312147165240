.masonry {
    @include clearfix;
    margin: 0 auto;

    &__sizer,
    &__item {
        @include grid-col(12);

        @include breakpoint(mobile) {
            @include grid-col(6);
        }

        @include breakpoint(medium) {
            @include grid-col(4);
        }
    }

    &__item {
        &__wrapper {
            position: relative;
            margin-bottom: $spacing;
            border-radius: $global-radius;
            overflow: hidden;

            @each $color in ('yellow' 'red' 'green' 'blue' 'darkblue') {
                &--#{$color} {
                    .masonry__item__lock,
                    h3 {
                        color: color('#{$color}');
                    }

                    .masonry__item__meta,
                    .masonry__item__restricted-access,
                    .btn {
                        background-color: color('#{$color}');
                    }

                    .btn {
                        @include hover {
                            @if $color == 'darkblue' {
                                background-color: color('primary');
                            } @else {
                                background-color: color('#{$color}-darker');
                            }
                        }
                    }
                }
            }

            .btn {
                position: absolute;
                right: 0;
                bottom: 0;
            }
        }

        &__link {
            display: block;
        }

        &__thumbnail {
            position: relative;

            &.compat-object-fit {
                width: 100%;
                min-height: 200px;
            }

            img {
                display: block;
            }
        }

        &__link,
        &__thumbnail {
            position: relative;
            border-radius: $global-radius $global-radius 0 0;
            overflow: hidden;
        }

        &__title {
            font-size: 1rem;
        }

        &__content {
            border: 3px solid color('cloudy-blue-2');
            border-bottom: 0;
            border-top-left-radius: $global-radius;
            border-top-right-radius: $global-radius;
            padding: $spacing / 2 calc(#{$spacing} + 50px) $spacing $spacing;

            *:first-child {
                margin-top: 0;
            }

            .search--masonry & {
                border: 3px solid color('cloudy-blue-2');
                border-radius: $global-radius;
            }

            .masonry__item__thumbnail + & {
                border-top: 0;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }
        }

        &__meta {
            position: relative;
            border-bottom-left-radius: $global-radius;
            border-bottom-right-radius: $global-radius;
            padding: $spacing / 2 $spacing;
            color: color('white');
            font-size: 0.8rem;

            .btn {
                top: 1px; // fix rounding issues
                right: 0;
                bottom: auto;
                transform: translateY(-100%);
                border-bottom-right-radius: 0;
            }
        }

        &__lock {
            @include absolute-center;
            border: 5px solid currentColor;
            border-radius: 50%;
            background: color('white');
            padding: 0.5rem;
            line-height: $line-height-base;
            font-size: 3rem;

            .icon {
                display: block;
            }
        }
    }
}
