.post__meta {
    margin-bottom: $spacing;
    border: 3px solid color('cloudy-blue-2');
    border-radius: $global-radius;
    padding: $spacing;

    .list--bare {
        li {
            margin-top: $spacing / 3;
        }

        ul li {
            margin-top: 0;
        }
    }

    &__tags {
        position: relative;
        padding-left: $spacing;
        font-size: 0.8rem;

        &::before {
            @extend %icon;

            position: absolute;
            left: $spacing / 6;
            content: icon-char('tag');
        }

        li {
            padding-left: $spacing / 3;
        }

        &.list--no-seperator {
            li::after {
                display: inline;
                padding: 0;
                content: ',';
            }

            li:last-child::after {
                display: none;
                content: '';
            }
        }
    }
}
