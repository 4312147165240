.label-floating {
    // floating label container styling
    position: relative;
    margin-right: $spacing * 2;
    border-bottom: $input-border;
    box-shadow: $input-shadow;
    background-color: $input-background;
    padding: ($spacing * 1.7) ($spacing / 2) ($spacing / 3);
    font-size: $input-font-size;

    // only as height as the font size is (+ some extra)
    input:not([type='submit']):not([type='file']),
    .label-floating__label {
        height: 1.2em;
        line-height: $line-height-base;
    }

    // style our input fields
    input:not([type='submit']):not([type='file']),
    textarea {
        margin: 0;
        border: 0;
        border-radius: 0;
        box-shadow: none;
        padding: 0;
        width: 100%;

        // we want no placeholder, we use our floating label
        &::placeholder {
            color: transparent;
        }
    }

    select {
        @include hover($parent: true) {
            box-shadow: none;
        }
    }

    .label-floating__label {
        position: absolute;
        top: 2em;
        transition: top $animation-duration, font-size $animation-duration, color $animation-duration;
        color: $input-placeholder-color;
        font-size: 0.85rem;
    }

    &--required::after {
        display: block;
        position: absolute;
        top: 50%;
        right: -$spacing;
        transform: translate(50%, -50%);
        line-height: $line-height-base;
        font-size: 1rem;
        content: '*';
    }

    // //////////////////////////////////////////////////////////
    // active input fields = filled value
    &--active {
        .label-floating__label {
            top: 1em;
            color: $form-label-color-active;
            font-size:  0.75em;
        }
    }



    // //////////////////////////////////////////////////////////
    // focused input fields
    &--focus {
        border-bottom: $input-border-focus;
        box-shadow: $input-shadow-focus;
        background: $input-background-focus;

        .label-floating__label {
            color: $form-label-color-active;
        }
    }



    // //////////////////////////////////////////////////////////
    // form errors
    &.has-error {

        border-bottom: $input-border-alert;
        box-shadow: $input-shadow-alert;

        &::after {
            @extend %icon;
            color: color('alert');
            content: icon-char('alert');
        }

        .label-floating {
            &__label {
                color: $form-label-color-alert;
            }
        }
    }

    &.is-valid {
        border-bottom: $input-border-success;
        box-shadow: $input-shadow-success;

        .label-floating__label {
            color: $form-label-color-success;
        }
    }
}

// override foundation's border-radius logic
.input-group,
.wpcf7-form-control-wrap {
    .label-floating,
    > .label-floating:first-child,
    > .label-floating:last-child,
    .wpcf7-select {
        border-radius: $input-radius 0;
    }
}
