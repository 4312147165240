.video-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed,
.video-container video,
.video-container img,
.rll-youtube-player img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.rll-youtube-player img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100% !important; // sass-lint:disable-line no-important
}
