.utility-nav {
    @include breakpoint(xmedium) {
        display: flex;
        position: absolute;
        top: 0;
        right: 0;
        align-items: stretch;
        justify-content: flex-end;
        border-bottom-left-radius: $global-radius;
        background: background('cloudy-blue-2');
        padding-right: $spacing / 3;
        width: percentage(2 / 3);
    }

    .search {
        input {
            @include font-special;

            border: 0;
            box-shadow: none;
            text-align: right;
            font-weight: 500;

            &::placeholder {
                transition: color $animation-duration;
                color: color('bluey-grey');
            }

            &:focus,
            &:active {
                &::placeholder {
                    color: transparent;
                }
            }
        }

        .btn {
            border-radius: 0;
            padding: 0 $spacing / 3;
            color: color('bluey-grey');
        }

        input,
        .btn {
            background: color('marine-blue');
        }

        @include breakpoint(xmedium) {
            display: inline-block;
            width: 100%;
            max-width: 40%;

            .input-group {
                margin-bottom: 0;
            }

            input {
                background: transparent;
            }

            .btn {
                background: none;
            }
        }
    }
}
