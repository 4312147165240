.infotag2019 {
    // Primary Nav
    .primary-nav {
        // default navigation design on big screens
        @include breakpoint(xmedium) {
            background-color: color('darkblue');

            .is-dropdown-submenu {
                background-color: transparentize(color('infotag2019-darkest'), 0.2);

                .menu-item {
                    a {
                        border-bottom: 3px solid color('infotag2019');

                        @include hover {
                            background-color: color('infotag2019-darkest');
                        }
                    }
                }
            }

            .is-dropdown-submenu-item {
                &.current-menu-item {
                    a {
                        background-color: color('infotag2019-darker');
                    }
                }
            }

            .accordion-menu {
                .menu-item {
                    a {
                        border-bottom: 3px solid color('infotag2019-darker');
                    }
                }

                .current-menu-item {
                    background-color: color('infotag2019-dark');
                }
            }

            > .menu > .menu-item {
                @include hover {
                    background-color: color('infotag2019-darker');
                }
            }

            .current-page-ancestor,
            .current-menu-item {
                > a {
                    background-color: color('infotag2019-dark');
                }
            }

            .is-dropdown-submenu {
                .current-menu-item,
                .current-menu-ancestor {
                    > a {
                        background-color: color('infotag2019-darkest');
                    }
                }
            }
        }
    }

    // off-canvas-btn
    .btn--menu-toggle {
        background-color: color('infotag2019-darkest');
    }

    // Off-Canvas Element
    .off-canvas {
        // background
        background-color: color('darkblue');

        .primary-nav {
            .accordion-menu {
                .menu-item a {
                    // underline
                    border-bottom-color: color('infotag2019-dark');
                }

                // active LI
                .current-menu-item {
                    background-color: transparent;

                    a {
                        background-color: color('infotag2019-darker');
                    }
                }
            }
        }

        // search
        .utility-nav .search input,
        .utility-nav .search .btn {
            background-color: color('infotag2019-darker');

            @include breakpoint(xmedium) {
                background-color: transparent;
            }
        }
    }

    // ECR Austria back button
    .btn--ecr-tag {
        background: background('white');
        color: color('darkblue');

        &::after {
            background: background('white');
        }

        @include hover {
            &::after {
                background-color: color('infotag2019-lighter');
            }
        }
    }

    // blue bg too turquoise (e.g. aside)
    .bg--blue {
        background-color: color('infotag2019');

        ul li {
            border-bottom-color: color('infotag2019-lighter');
        }

        a {
            background-image: linear-gradient(0deg, color('infotag2019-lighter') calc(1em + 6px), transparent calc(1em + 6px));
        }
    }

    .bg--darkblue {
        ul li {
            border-bottom-color: color('cloudy-blue-2');
        }
    }

    // table
    thead {
        background: color('infotag2019');

        td {
            border-left: 3px solid color('infotag2019-dark');
        }
    }

    // headlines & subline
    h4,
    h5,
    h6 {
        &,
        sub {
            color: color('infotag2019-dark');
        }
    }

    h2 {
        &,
        sub {
            color: color('darkblue');
        }
    }

    // bg header img
    .bg--header-image {
        background-size: cover;
        height: 150px;
    }

    .teaser__tabs__item {
        background-color: transparentize(color('white'), 0.2);

        a,
        .btn {
            color: color('primary');
        }

        .btn {
            background-color: color('white');
        }
    }
}
