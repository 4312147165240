.clearfix {
    @include clearfix;
}

%inline,
.inline {
    display: inline-block;
    float: none;
    width: auto;
}

.center {
    margin-right: auto;
    margin-left: auto;
    text-align: center;
}

.right {
    text-align: right;
}

.image-replacement {
    border: 0;
    background-color: transparent;
    overflow: hidden;

    &::before {
        display: block;
        width: 0;
        height: 150%;
        content: ' ';
    }
}

%visuallyhidden,
.visuallyhidden {
    position: absolute;
    margin: -1px;
    border: 0;
    padding: 0;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0 0 0 0);

    &.focusable:active,
    &.focusable:focus {
        position: static;
        margin: 0;
        width: auto;
        height: auto;
        overflow: visible;
        clip: auto;
    }
}

[v-cloak] {
    display: none;
}

.flex-spacer {
    flex-grow: 1;
}

[data-random] {
    visibility: hidden;

    &.is-init {
        visibility: visible;
    }
}
