.scroll-up {
    @include icon('scroll-up');
    @include zindex(overlay, 1);

    display: none;
    position: fixed;
    right: $spacing / 4;
    bottom: $spacing / 3;
    transition: margin-left $animation-duration, margin-right $animation-duration;

    &::before {
        // somehow, the inner of the scroll-up-icon gets transparent when creating the icon-font, here is a workaround:
        background: linear-gradient(to left, $body-background 100%, transparent 100%) no-repeat center;
        background-size: 50% 50%;
        font-size: 2.75em;
    }

    @include breakpoint(mobile down) {
        // classes are set by Foundation, no distinct class possible
        // sass-lint:disable ChainedClasses
        .is-off-canvas-open.is-open-right & {
            margin-right: map-get($offcanvas-sizes, small);
        }

        .is-off-canvas-open.is-open-left & {
            margin-left: map-get($offcanvas-sizes, small);
        }
        // sass-lint:enable
    }
}
