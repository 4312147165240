.cc-floating.cc-theme-classic {
    @include font-default;
}

.cc-floating.cc-theme-classic .cc-btn.cc-dismiss,
.browser-alert__close {
    @extend %btn;
    @extend %btn--icon;

    position: absolute;
    right: 0;
    bottom: 0;
    transition: background $animation-duration;
    border-radius: $global-radius 0;
    width: auto;
    min-width: 0;

    @include hover {
        background: color('peacock-blue');
        color: color('white');
    }
}

.browser-alert {
    @include zindex('critical');

    position: fixed;
    right: 1em;
    bottom: 1em;
    margin: 0;
    padding: $spacing;
    width: 24em;
    text-align: center;
    color: $browser-alert-color;
    font-size: 16px; // no rescaling

    a[target='_blank'] {
        @include hover($parent: true) {
            text-decoration: underline;
            color: $browser-alert-color-link;
        }
    }

    // 24px is to specific for a variable
    // value is required for mimicking the cookieconsent button
    // sass-lint:disable variable-for-property
    &__close {
        border-color: transparent;
        background: color('primary');
        line-height: 24px;
        color: color('white');
    }
    // sass-lint:enable
}

// media-query copied from cookieconsent plugin
@media screen and (max-width: 414px) and (orientation: portrait), screen and (max-width: 736px) and (orientation: landscape) {
    .browser-alert {
        right: 0;
        bottom: 0;
        width: auto;
    }
}

// importat is required to overwrite cookieconsnet plugin settings
// sass-lint:disable no-important
.cc-floating.cc-theme-classic,
.browser-alert {
    border-radius: $global-radius;
    background: $browser-alert-color-bg !important;
}
// sass-lint:enable

// display browsehappy only if we have an "old" browser
.browser-alert--browsehappy {
    display: none;
}

// don't display activate cookies if they are activated
.browser-alert--activate-cookies {
    display: none;

    html.no-cookies & {
        display: block;
    }
}
