// common Vars
// ------------
$null: 0;

// use flexbox for our grid
// -------------------------
$flex: false;

// max & min width of your site
// -----------------------------
$max-width: strip-units($global-width) * (16px * strip-units($global-font-size / 100)); // calc from foundation settings
$min-width: 320px;

// typography
// -----------
$font-size-max: strip-units($global-font-size / 100) * 16px; // calc from foundation settings
$font-size-min: 13px;
$line-height-base: 1;

// spacing
// --------
// use this variable for all your spacings like paddings and
// margins to easily change them via one variable
$spacing: $global-lineheight * 1em;

// forms
// ------
$form-icon-size:     2em;

// checkbox & radiobutton
// -----------------------
$form-check-width:   2em;
$form-check-height:  2em;
$form-checked-color: color('marine-blue');

// browser alerts
// ---------------
$browser-alert-color: color('white');
$browser-alert-color-link: color('white');
$browser-alert-color-bg: transparentize(color('primary'), 0.2);

// animation
// ----------
$animation-duration: 0.3s;
$animation-timing:   ease;

// preloaders
// -----------
$preloader-color: color('selection');

// logos
// -----
$logo-column-gutter: 20px;
$logo-item-width-small: 152px;
$logo-item-width-medium: 160px;
$logo-item-height-small: $logo-item-width-small - $logo-column-gutter;
$logo-item-height-medium: $logo-item-width-medium - $logo-column-gutter;

// misc
// ----
$utility-nav-link-height: 40px;
$login-form-height: 90px;
$block-list-min-height: rem-calc(200);
