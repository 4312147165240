.infotag2018 {
    // Primary Nav
    .primary-nav {
        // default navigation design on big screens
        @include breakpoint(xmedium) {
            background-color: color('darkblue');

            .is-dropdown-submenu {
                background-color: transparentize(color('forgetmenot-darkest'), 0.2);

                .menu-item {
                    a {
                        border-bottom: 3px solid color('forgetmenot');

                        @include hover {
                            background-color: color('forgetmenot-darkest');
                        }
                    }
                }
            }

            .is-dropdown-submenu-item {
                &.current-menu-item {
                    a {
                        background-color: color('forgetmenot-darker');
                    }
                }
            }

            .accordion-menu {
                .menu-item {
                    a {
                        border-bottom: 3px solid color('forgetmenot-darker');
                    }
                }

                .current-menu-item {
                    background-color: color('forgetmenot-dark');
                }
            }

            > .menu > .menu-item {
                @include hover {
                    background-color: color('forgetmenot-darker');
                }
            }

            .current-page-ancestor,
            .current-menu-item {
                > a {
                    background-color: color('forgetmenot-dark');
                }
            }

            .is-dropdown-submenu {
                .current-menu-item,
                .current-menu-ancestor {
                    > a {
                        background-color: color('forgetmenot-darkest');
                    }
                }
            }
        }
    }

    // off-canvas-btn
    .btn--menu-toggle {
        background-color: color('forgetmenot-darkest');
    }

    // Off-Canvas Element
    .off-canvas {
        // background
        background-color: color('darkblue');

        .primary-nav {
            .accordion-menu {
                .menu-item a {
                    // underline
                    border-bottom-color: color('forgetmenot-dark');
                }

                // active LI
                .current-menu-item {
                    background-color: transparent;

                    a {
                        background-color: color('forgetmenot-darker');
                    }
                }
            }
        }

        // search
        .utility-nav .search input,
        .utility-nav .search .btn {
            background-color: color('forgetmenot-darker');

            @include breakpoint(xmedium) {
                background-color: transparent;
            }
        }
    }

    // ECR Austria back button
    .btn--ecr-tag {
        background: background('white');
        color: color('darkblue');

        &::after {
            background: background('white');
        }

        @include hover {
            &::after {
                background-color: color('forgetmenot-lighter');
            }
        }
    }

    // blue bg too turquoise (e.g. aside)
    .bg--blue {
        background-color: color('forgetmenot');

        ul {
            li {
                border-bottom-color: color('forgetmenot-lighter');
            }
        }
    }

    // table
    thead {
        background: color('forgetmenot');

        td {
            border-left: 3px solid color('forgetmenot-dark');
        }
    }

    // headlines & subline
    h4,
    h5,
    h6 {
        &,
        sub {
            color: color('forgetmenot-dark');
        }
    }

    h2 {
        &,
        sub {
            color: color('darkblue');
        }
    }

    // bg header img
    .bg--header-image {
        background-size: cover;
        height: 150px;
    }

    .teaser__tabs__item {
        background-color: transparentize(color('white'), 0.2);

        a,
        .btn {
            color: color('primary');
        }

        .btn {
            background-color: color('white');
        }
    }
}
