// style checkoxes with SVGs when the browser has support for them
// the checked symbol is hidden/shown via CSS and the `currentColor` attribute on the SVG element
// this way you can show/hide the element by changing the color attribute of the SVG via CSS to transparent or a color value (#000)

// hide SVGs on devices which don't support SVGs
.label--checkbox,
.label--radio {
    position: relative;
    margin-bottom: 0.25em;
    padding-left: 2.5em;
    min-height: $form-check-height;

    input,
    svg {
        position: absolute;
        top: 0;
        left: 0;
    }

    svg {
        display: none;
    }

    &--required {
        margin-right: $spacing * 2;

        &::after {
            display: block;
            position: absolute;
            top: 50%;
            right: -1.2em;
            transform: translate(50%, -50%);
            line-height: $line-height-base;
            font-size: 1rem;
            content: '*';
        }

        &.has-error::after {
            @extend %icon;
            color: color('alert');
            content: icon-char('alert');
        }


        + .form-errors {
            padding-left: 2.5em / 18 * 14; // padding as if it would be 14px font-size
        }
    }
}

.label--inline {
    display: inline-block;
    margin-bottom: 0;
    margin-left: 0.75em;

    &:first-of-type {
        margin-left: 0;
    }
}

// we can use SVGs in our browser => style all the things
html.svg {
    .label--checkbox,
    .label--radio {
        // hide the default input
        input {
            // don't user display: none, with this method you disable the elements accessibility
            position: absolute;
            margin: -1px;
            border: 0;
            padding: 0;
            width: 1px;
            height: 1px;
            overflow: hidden;
            clip: rect(0 0 0 0);

            // round the border of radio buttons for nice focus styling
            &[type='radio'] ~ svg {
                border-radius: 50%;
            }

            // hide the checked symbol via the `currentColor` attribute
            ~ svg {
                color: transparent;
                pointer-events: none;
            }

            // show the checked symbol when the input is :checked
            &:checked ~ svg {
                color: $form-checked-color;
            }

            // disable the inputs
            &[disabled] ~ svg {
                opacity: 0.5;
            }
        }

        // display the svg in a relative dimension to the label
        svg {
            display: inline-block;
            border-radius: 0;
            width: $form-check-width;
            height: $form-check-height;
        }
    }
}
