// disable the extend linting because foundation doesn't support this
// sass-lint:disable placeholder-in-extend
// also enable the use of the .btn class
.wpcf7-form-control-wrap {
    @extend .input-group;
}
// sass-lint:enable placeholder-in-extend

.wpcf7 {
    .screen-reader-response {
        @include element-invisible;
    }
}

.wpcf7-list-item {
    display: inline-block;
}

.wpcf7-response-output {
    margin-bottom: $spacing / 2;
    padding: $spacing / 2 0;
    color: color('white');

    &:empty {
        display: none;
    }

    &.wpcf7-validation-errors,
    &.wpcf7-mail-sent-ng,
    &.wpcf7-spam-blocked {
        color: $alert-color;
    }

    &.wpcf7-mail-sent-ok {
        color: color('primary');
    }
}
