// TODO: this template is a mess, it should not be re-used another time
.ecr-tag {
    // Primary Nav
    .primary-nav {
        // default navigation design on big screens
        @include breakpoint(xmedium) {
            background-color: var(--color--ecr-tag--lighter);

            .is-dropdown-submenu {
                background-color: var(--color--ecr-tag--lighter--80);

                .menu-item {
                    a {
                        border-bottom: 3px solid var(--color--ecr-tag);

                        @include hover {
                            background-color: var(--color--ecr-tag);
                        }
                    }
                }
            }

            .is-dropdown-submenu-item {
                &.current-menu-item {
                    a {
                        background-color: var(--color--ecr-tag);
                    }
                }
            }

            .accordion-menu {
                .menu-item {
                    a {
                        border-bottom: 3px solid var(--color--ecr-tag--darker);
                    }
                }

                .current-menu-item {
                    background-color: var(--color--ecr-tag--dark);
                }
            }

            > .menu > .menu-item {
                @include hover {
                    background-color: var(--color--ecr-tag);
                }
            }

            .current-page-ancestor,
            .current-menu-item {
                > a {
                    background-color: var(--color--ecr-tag);
                }
            }

            .is-dropdown-submenu {
                .current-menu-item,
                .current-menu-ancestor {
                    > a {
                        background-color: var(--color--ecr-tag--darkest);
                    }
                }
            }
        }
    }

    // off-canvas-btn
    .btn--menu-toggle {
        background-color: var(--color--ecr-tag--darker);
    }

    // Off-Canvas Element
    .off-canvas {
        // background
        background-color: var(--color--ecr-tag--lighter);

        .primary-nav {
            .accordion-menu {
                .menu-item a {
                    // underline
                    border-bottom-color: var(--color--ecr-tag);
                }

                // active LI
                .current-menu-item {
                    background-color: transparent;

                    > a {
                        background-color: var(--color--ecr-tag);
                    }

                    .submenu {
                        background-color: transparent;
                    }
                }
            }

            ul li a {
                color: color('white');
            }
        }

        // search
        .utility-nav .search input,
        .utility-nav .search .btn {
            background-color: var(--color--ecr-tag--darker);

            @include breakpoint(xmedium) {
                background-color: transparent;
            }
        }
    }

    // ECR Austria back button
    .btn--ecr-tag,
    .sidebar__block .btn {
        background-color: var(--color--ecr-tag--darker);

        &::after {
            background-color: var(--color--ecr-tag--darkest);
        }

        @include hover {
            &::after {
                background-color: var(--color--ecr-tag--darker);
            }
        }
    }

    // headlines & subline
    h2,
    .h2,
    h3,
    .h3,
    h4,
    .h4,
    h5,
    .h5,
    h6,
    .h6 {
        &,
        sub {
            color: var(--color--ecr-tag--lighter);
        }
    }

    .sidebar__block {
        h2,
        .h2,
        h3,
        .h3,
        a {
            color: color('white');
        }
    }

    // table
    thead {
        background: var(--color--ecr-tag);

        td {
            border-left: 3px solid var(--color--ecr-tag--dark);
        }
    }

    // bg header img
    .bg--header-image {
        background-size: cover;
        height: 150px;
    }

    .btn--submit {
        background-color: var(--color--ecr-tag--lighter);

        &::after {
            background-color: var(--color--ecr-tag);
        }

        @include hover {
            &::after {
                background-color: var(--color--ecr-tag--lighter);
            }
        }
    }
}
