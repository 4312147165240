.block {
    &__headline {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: $spacing;
        background: linear-gradient(to top, color('cloudy-blue-2') 3px, transparent 3px);

        h2 {
            text-transform: uppercase;
            color: color('primary');
            font-size: 1.3rem;
        }

        &--with-btn {
            margin-right: $spacing * 2;

            .btn {
                bottom: -3px; // hide border, border width
                margin-right: -$spacing * 2;
                margin-bottom: -$header-margin-bottom * 2; // against h2 margin bottom
            }
        }
    }

    &__list {
        &__item {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: stretch;
            margin-bottom: $spacing;
            width: 100%;

            @include breakpoint(medium) {
                flex-wrap: nowrap;
                min-height: $block-list-min-height;
            }

            &--reverse {
                &,
                > a,
                > a[target='_blank'] {
                    flex-direction: row-reverse;
                }
            }

            &[data-clickable] {
                cursor: pointer;
            }

            @each $color in ('yellow' 'red' 'green' 'blue' 'turquois' 'forgetmenot' 'infotag2019' 'infotag2020' 'infotag2021') {
                &--#{$color} {
                    h3 {
                        margin-top: $spacing / 2;
                        color: color('#{$color}');
                    }

                    .btn {
                        background-color: color('#{$color}');

                        @include hover {
                            background-color: color('#{$color}-darker');
                        }
                    }
                }
            }

            &--ecr-tag {
                h3 {
                    margin-top: $spacing / 2;
                    color: var(--color--ecr-tag);
                }

                .btn {
                    background-color: var(--color--ecr-tag);

                    @include hover {
                        background-color: var(--color--ecr-tag--darker);
                    }
                }
            }
        }

        &__text {
            position: relative;
            margin-right: 1px; // border rounding issues
            border: 2px solid color('cloudy-blue-2');
            border-top: 0;
            border-radius: 0 0 $global-radius $global-radius;
            padding: $spacing;
            color: color('darkblue');

            *:first-child {
                margin-top: 0;
            }

            .btn {
                position: absolute;
                right: -2px;
                bottom: -2px;
            }
        }

        &__image {
            position: relative;

            &.compat-object-fit {
                border-radius: $global-radius $global-radius 0 0;
                min-height: 300px;
            }

            img {
                display: block;
                border-radius: $global-radius $global-radius 0 0;
                width: 100%;
                height: 100%;
                object-fit: cover;

                @include breakpoint(medium) {
                    position: absolute;
                }

                .block__list__item--image & {
                    position: relative;
                }
            }

            &__link {
                &,
                &[target="_blank"]:not(.download) {
                    display: block;
                }
            }
        }

        @include breakpoint(medium) {
            &__text--left,
            &__image--left img,
            &__image--left.compat-object-fit {
                border-radius: $global-radius 0 0 $global-radius;
            }

            &__text--right,
            &__image--right img,
            &__image--right.compat-object-fit {
                border-radius: 0 $global-radius $global-radius 0;
            }

            &__text {
                border-top: 2px solid color('cloudy-blue-2');

                &--left {
                    margin-right: 0;
                    border-right: 0;
                    border-left: 2px solid color('cloudy-blue-2');

                    .btn {
                        right: 0;
                        border-bottom-right-radius: 0;
                    }
                }

                &--right {
                    border-left: 0;
                }
            }
        }
    }
}
