.form-errors {
    @extend %list--bare;
    color: $alert-color;
}

// we need this for greater specificity...
ul.form-errors li {
    border: 0;
}

.label-floating .form-errors {
    @extend %list--inline;
    @extend %list--no-seperator;

    display: inline-block;

    li {
        display: inline-block;

        &:first-child::before {
            display: inline-block;
            position: relative;
            top: auto;
            left: auto;
            transform: none;
            margin: 0 $spacing / 3;
            border-radius: 0;
            background: none;
            width: auto;
            height: auto;
            content: '-';
        }
    }
}



.input-group.has-error::after {
    @include vertical-center;

    display: block;
    right: 0.5em;
    background: url('/assets/img/validation/alert.svg') no-repeat center;
    background-size: contain;
    width: 1.5em;
    height: 1.5em;
    content: '';
}
