.logo-item {
    $logo-spacing: 15px;

    @include grid-col-gutter($logo-spacing, (15, 20));

    display: inline-block;
    margin-bottom: $logo-spacing;
    width: $logo-item-width-small;
    height: $logo-item-height-small;

    @include breakpoint(medium) {
        width: $logo-item-width-medium;
        height: $logo-item-height-medium;
    }

    a {
        @include no-link-icon;

        display: block;
        height: 100%;

        &.compat-object-fit {
            border: 3px solid color('light-gray');
            border-radius: 20px;
            background-size: cover;
        }
    }

    &__error {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        text-align: center;
        color: color('alert');
    }

    img {
        border: 3px solid color('light-gray');
        border-radius: 20px;
    }

    &--no-frame {
        a.compat-object-fit,
        img {
            border: 0;
        }
    }
}

.logos--grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.logos--swiper .swiper-slide {
    width: $logo-item-width-small;

    @include breakpoint(medium) {
        width: $logo-item-width-medium;
    }
}

.logos--masonry .masonry__sizer,
.masonry__item.masonry__item--logos {
    margin-right: $logo-column-gutter / 2;
    margin-bottom: $logo-column-gutter;
    margin-left: $logo-column-gutter / 2;
    padding: 0;
    width: $logo-item-width-small - $logo-column-gutter;

    @include breakpoint(medium) {
        margin-right: $logo-column-gutter / 2;
        margin-left: $logo-column-gutter / 2;

        width: $logo-item-width-medium - $logo-column-gutter;
    }

    .logo-item {
        padding: 0;
        max-width: 100%;
        height: auto;
    }
}
