// disable the extend linting because foundation doesn't support this
// sass-lint:disable placeholder-in-extend
// also enable the use of the .btn class
.btn {
    @extend .button;
}
// sass-lint:enable placeholder-in-extend

%btn,
.btn,
.button {
    position: relative;
    padding-right: 3.75em;

    &::after {
        @extend %icon;

        display: inline-block;
        position: absolute;
        top: -1px;
        right: -1px;
        transition: background-color $animation-duration;
        border: 1px solid transparent;
        border-radius: $global-radius 0;
        background-color: color('yellow-darker');
        padding: $button-padding;
        content: icon-char('arrow-right');
    }

    @include hover {
        &::after {
            background-color: color('yellow-dark');
        }
    }

    .icon,
    .label {
        vertical-align: middle;
    }

    use {
        fill: $button-color;
    }

    %btn--icon,
    &--icon {
        padding-right: 1em;

        &::after {
            display: none;
        }
    }

    &--close {
        border-radius: 0;
        padding-right: 1em;

        @include hover($parent: true) {
            background: none;
        }

        &::after {
            display: none;
        }
    }

    &__preloader {
        display: none;
        margin-left: 1em;
        width: 25px;

        .is-loading & {
            display: inline-block;
        }

        .preloader {
            top: -2px;
        }
    }

    &--menu-toggle {
        background: background('primary');
    }

    &--login {
        transition: width $animation-duration, padding-left $animation-duration, background-color $animation-duration, color $animation-duration, border-top-right-radius $animation-duration;
        border-radius: 0 0 0 $global-radius;
        padding-right: 1em;
        padding-left: 3.75em;
        width: 165px;

        &::after {
            right: auto;
            bottom: -1px;
            left: -1px;
            transition: background-color $animation-duration, color $animation-duration;
            border-radius: 0 $global-radius;
            color: color('white');
        }

        &[data-logged-in='true'] {
            &::after {
                content: icon-char('lock-open');
            }
        }

        &[data-logged-in='false'] {
            &::after {
                content: icon-char('lock-closed');
            }
        }

        &.is-form-open,
        .mobile-button-group & {
            border-top-right-radius: $global-radius;
            padding-left: 1em;
            width: 3.15em;
            overflow: hidden;
            color: transparent;
        }

        &.is-form-open {
            background-color: color('pale-grey');

            &::after {
                background-color: color('pale-grey');
                color: color('bluey-grey');
                content: icon-char('arrow-down');
            }

            @include hover {
                &,
                &::after {
                    background-color: color('bluey-grey');
                    color: color('white');
                }
            }

        }

        .mobile-button-group & {
            &,
            .header & {
                display: inline-block;
            }
        }
    }

    &--submit {
        font-weight: 700;

        @include hover($parent: true) {
            background-color: color('blue');
        }

        &::after {
            background-color: color('peacock-blue');
        }

        @include hover {
            &::after {
                background-color: color('primary');
            }
        }
    }

    &--ecr-tag {
        font-weight: 700;

        @include hover($parent: true) {
            background-color: #af1613; // sass-lint:disable-line no-color-literals
        }

        &::after {
            background-color: #de1719; // sass-lint:disable-line no-color-literals
        }

        @include hover {
            &::after {
                background-color: #af1613; // sass-lint:disable-line no-color-literals
            }
        }
    }

    &--section {
        @include hover($parent: true) {
            background-color: color('cloudy-blue');
        }

        &::after {
            background-color: color('bluey-grey');
        }

        @include hover {
            &::after {
                background-color: color('battleship-grey');
            }
        }
    }

    &--simple {
        border-radius: 0;
        background-color: color('peacock-blue');
        padding: $button-padding;

        &::after {
            display: none;
        }

        @include hover {
            background-color: color('darkblue');
        }
    }
}

.mobile-button-group {
    @include hide-for('xmedium');

    position: absolute;
    top: 0;
    right: 0;

    .btn {
        display: inline-block;
        height: 40px;

        &:first-child {
            @include zindex(base, 1);
            position: absolute;

            border-radius: 0 $global-radius;
            background-color: color('yellow');
        }

        &:last-child {
            border-radius: 0 0 0 $global-radius;
            padding-left: 3.75em;
        }
    }
}
