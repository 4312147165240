.map__iframe {
    position: relative;
    margin-bottom: $spacing;
    padding-bottom: 56.25%;
    width: 100%;
    height: 0;
    overflow: hidden;

    iframe {
        max-width: 100%;
    }
}
